var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"content-wrapper",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row justify-start align-center mb-5"},[_c('h4',{staticClass:"fw-600 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("SigninUserAndRole.title_invite_user"))+" ")]),_c('v-btn',{staticClass:"ml-3",class:_vm.checking_email && 'animate__spining',attrs:{"icon":"","disabled":_vm.checking_email},on:{"click":function($event){return _vm.initAllUser()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1),_c('div',{staticClass:"content-body"},[_c('div',{staticClass:"row match-height"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",attrs:{"id":"recent-transactions"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content px-2 py-4"},[_c('form',{staticClass:"form form-horizontal"},[_c('div',{staticClass:"form-body"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 label-control text-right",attrs:{"for":"projectinput1"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("SigninUserAndRole.email"))+" "),_c('span',{staticClass:"align-top"},[_vm._v("*")])])]),_c('div',{staticClass:"col-md-9 mx-auto"},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invite_user),expression:"invite_user"}],staticClass:"form-control mr-3",class:_vm.can_invite && 'border-success-focus',attrs:{"type":"text","id":"inviteemail","placeholder":_vm.$t('SigninUserAndRole.exam_email'),"name":"inviteemail"},domProps:{"value":(_vm.invite_user)},on:{"input":function($event){if($event.target.composing)return;_vm.invite_user=$event.target.value}}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.checkDupEmailFunc()}}},[(_vm.checking_email)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-icon',{attrs:{"color":_vm.can_invite == true
                                  ? 'success'
                                  : _vm.can_invite == false
                                  ? 'error'
                                  : 'warning'}},[_vm._v(" "+_vm._s(_vm.can_invite == true ? "mdi-check-circle-outline" : _vm.can_invite == false ? "mdi-close-circle-outline" : "mdi-alert-circle-outline")+" ")])],1)],1),_c('div',{staticClass:"d-flex flex-column"},[(!_vm.loadingInvite && !_vm.invite_user)?_c('small',{staticClass:"text-muted"},[_vm._v("*"+_vm._s(_vm.$t("SigninUserAndRole.desp_email")))]):_vm._e(),(
                              _vm.invite_user &&
                              !_vm.loadingInvite &&
                              !_vm.emailCheckedFormat
                            )?_c('small',{staticClass:"text-muted text-error"},[_vm._v("*"+_vm._s(_vm.$t("Signup.wrongemail")))]):_vm._e(),(_vm.invite_user && !_vm.loadingInvite && _vm.dup_email)?_c('small',{staticClass:"text-error"},[_vm._v("*"+_vm._s(_vm.$t("Alert.user_provider")))]):_vm._e(),(
                              _vm.invite_user && !_vm.loadingInvite && _vm.dup_property
                            )?_c('small',{staticClass:"text-error"},[_vm._v("*"+_vm._s(_vm.$t("Alert.user_in_property")))]):_vm._e(),(
                              _vm.invite_user &&
                              !_vm.loadingInvite &&
                              !_vm.dup_email &&
                              _vm.emailCheckedFormat
                            )?_c('small',{staticClass:"text-success"},[_vm._v("*"+_vm._s(_vm.$t("Alert.usercaninvite")))]):_vm._e()])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('v-btn',{staticClass:"btn invite btn-warning btn-min-width mb-1 mr-3 text-white-btn",attrs:{"depressed":"","disabled":_vm.loadingInvite},on:{"click":function($event){return _vm.goBackFunc()}}},[_vm._v(_vm._s(_vm.$t("SigninUserAndRole.btn_cancel")))]),_c('v-btn',{staticClass:"btn invite btn-primary btn-min-width mb-1 text-white-btn",attrs:{"depressed":"","disabled":_vm.loadingInvite},on:{"click":function($event){return _vm.saveFunc()}}},[_vm._v(_vm._s(_vm.$t("SigninUserAndRole.btn_invite")))])],1)])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }