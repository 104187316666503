<template>
  <v-container fluid class="content-wrapper">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">
        {{ $t("SigninUserAndRole.title_invite_user") }}
      </h4>
      <v-btn
        icon
        class="ml-3"
        @click="initAllUser()"
        :class="checking_email && 'animate__spining'"
        :disabled="checking_email"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div class="content-body">
      <div class="row match-height">
        <div class="col-md-12">
          <!-- Recent Booking -->
          <div class="row">
            <div id="recent-transactions" class="col-12">
              <div class="card">
                <div class="card-content px-2 py-4">
                  <form class="form form-horizontal">
                    <div class="form-body">
                      <div class="row">
                        <label
                          class="col-md-3 label-control text-right"
                          for="projectinput1"
                        >
                          <b>
                            {{ $t("SigninUserAndRole.email") }}
                            <span class="align-top">*</span>
                          </b>
                        </label>
                        <div class="col-md-9 mx-auto">
                          <div
                            class="d-flex flex-row align-center justify-space-between"
                          >
                            <input
                              type="text"
                              id="inviteemail"
                              class="form-control mr-3"
                              :class="can_invite && 'border-success-focus'"
                              :placeholder="$t('SigninUserAndRole.exam_email')"
                              name="inviteemail"
                              v-model="invite_user"
                            />
                            <v-btn icon @click="checkDupEmailFunc()">
                              <v-progress-circular
                                v-if="checking_email"
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                              <v-icon
                                v-else
                                :color="
                                  can_invite == true
                                    ? 'success'
                                    : can_invite == false
                                    ? 'error'
                                    : 'warning'
                                "
                              >
                                {{
                                  can_invite == true
                                    ? "mdi-check-circle-outline"
                                    : can_invite == false
                                    ? "mdi-close-circle-outline"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </div>
                          <div class="d-flex flex-column">
                            <small
                              v-if="!loadingInvite && !invite_user"
                              class="text-muted"
                              >*{{ $t("SigninUserAndRole.desp_email") }}</small
                            >
                            <small
                              v-if="
                                invite_user &&
                                !loadingInvite &&
                                !emailCheckedFormat
                              "
                              class="text-muted text-error"
                              >*{{ $t("Signup.wrongemail") }}</small
                            >
                            <small
                              v-if="invite_user && !loadingInvite && dup_email"
                              class="text-error"
                              >*{{ $t("Alert.user_provider") }}</small
                            >
                            <small
                              v-if="
                                invite_user && !loadingInvite && dup_property
                              "
                              class="text-error"
                              >*{{ $t("Alert.user_in_property") }}</small
                            >
                            <small
                              v-if="
                                invite_user &&
                                !loadingInvite &&
                                !dup_email &&
                                emailCheckedFormat
                              "
                              class="text-success"
                              >*{{ $t("Alert.usercaninvite") }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <v-btn
                      depressed
                      :disabled="loadingInvite"
                      @click="goBackFunc()"
                      class="btn invite btn-warning btn-min-width mb-1 mr-3 text-white-btn"
                      >{{ $t("SigninUserAndRole.btn_cancel") }}</v-btn
                    >
                    <v-btn
                      depressed
                      @click="saveFunc()"
                      :disabled="loadingInvite"
                      class="btn invite btn-primary btn-min-width mb-1 text-white-btn"
                      >{{ $t("SigninUserAndRole.btn_invite") }}</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/ Recent Booking -->
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninInviteUserScreen",
  metaInfo: {
    title: "Invite",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    invite_user: "",
    can_invite: null,
    checking_email: false,
    dup_email: false,
    dup_property: false,
    emailCheckedFormat: false,
    value_element: "",
    itemsUserEmail: [],
    userData: null,
    loadingInvite: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initUserData();
    self.initAllUser();
  },
  watch: {
    invite_user() {
      const self = this;
      self.emailCheckedFormat = self.validateEmail(self.invite_user);
      // console.log("checkMail: ", self.emailCheckedFormat);
      self.can_invite = null;
      if (self.emailCheckedFormat) self.checkDupEmailFunc();
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus");
        element.classList.add("focus");
        this.value_element = val;
      }
    },
    goBackFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninUserScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      // EventBus.$emit("goBackPage");
      EventBus.$emit("endloading");
    },
    async initUserData() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") ||
              self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          console.log("user: ", res?.data?.result);
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || "Please try again",
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.userData = temp;
      }
    },
    saveFunc() {
      const self = this;
      self.loadingInvite = true;
      // console.log(self.invite_user, self.dup_email, self.can_invite);
      if (!self.invite_user || self.invite_user.length == 0) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SigninUserAndRole.pls_fill_email"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 2000,
          }
        );
      } else if (
        self.invite_user &&
        self.dup_email == false &&
        self.can_invite
      ) {
        swal(
          `${self.$t("SigninUserAndRole.want_to_invite")} ${
            self.invite_user
          } ${self.$t("SigninUserAndRole.want_to_invite2")}`,
          {
            buttons: {
              cancel: self.$t("Alert.btn_no"),
              confirm: {
                text: self.$t("Alert.btn_yes"),
                value: "confirm",
              },
            },
          }
        ).then(async (value) => {
          if (value === "confirm") {
            const obj = {
              email: self.invite_user,
              property: self.propertyID,
              // refer_by: self.userData?._id,
              // status: "pending",
            };
            EventBus.$emit("loadingtillend");

            try {
              const res = await self.axios.post(
                process.env.VUE_APP_API + `/staff/invite`,
                obj,
                {
                  headers: {
                    Authorization: self?.userToken,
                    "X-Language-Code":
                      localStorage?.getItem("language") ||
                      self?.$store?.state?.language,
                  },
                }
              );
              if (res?.status == 200) {
                const link = {
                  name: "SigninUserScreen",
                  params: {
                    propertyId: self.propertyID,
                    userToken: self.userToken,
                  },
                };
                swal(
                  self.$t("Alert.success_title"),
                  self.$t("Alert.invitesuccess"),
                  self.$t("Alert.success_label"),
                  {
                    button: false,
                    timer: 2000,
                  }
                ).then(() => {
                  // EventBus.$emit("changePathname", link);
                  EventBus.$emit("endloading");
                  EventBus.$emit("goBackPage");
                });
              } else {
                swal(
                  self.$t("Alert.warn_title"),
                  res?.data?.message || "Please try again",
                  self.$t("Alert.warn_label"),
                  {
                    button: false,
                    timer: 3000,
                  }
                );
              }
            } catch (error) {
              EventBus.$emit("endloading");
              console.log(error?.response?.data?.message || error);
              swal(
                self.$t("Alert.warn_title"),
                error?.response?.data?.message || "Please try again",
                self.$t("Alert.warn_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            }
          }
        });
      } else {
        swal(
          self.$t("Alert.warn_title"),
          `${
            self.invite_user
              ? self.dup_email && self.dup_property
                ? self.$t("Alert.user_provider") +
                  "\n" +
                  self.$t("Alert.user_in_property")
                : self.dup_email
                ? self.$t("Alert.user_provider")
                : self.dup_property
                ? self.$t("Alert.user_in_property")
                : ""
              : self.$t("Signup.wrongemail")
          }`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 2000,
          }
        ).then(() => (self.invite_user = ""));
      }
      self.loadingInvite = false;
    },
    async initAllUser() {
      const self = this;
      self.loadingInvite = true;
      self.checking_email = true;
      self.itemsUserEmail = [];
      var tmpEmailUser = [];
      var tmpEmailStaff = [];
      var tmpEmailPropertyUser = [];
      try {
        const resUser = await self.axios.get(
          process.env.VUE_APP_API + "/user_staff",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        // const resStaff = await self.axios.get(process.env.VUE_APP_API + "/staff", {
        //   headers: {
        //     Authorization:
        //       self?.userToken ||
        //       localStorage?.getItem("Token") ||
        //       self?.$store?.state?.resSignUpData?.access_token,
        //     "X-Language-Code":
        //       localStorage?.getItem("language") || self?.$store?.state?.language,
        //   },
        // });
        // const resPropertyUser = await self.axios.get(
        //   process.env.VUE_APP_API + `/staff/?property=${self.propertyID}`,
        //   {
        //     headers: {
        //       Authorization:
        //         self?.userToken ||
        //         localStorage?.getItem("Token") ||
        //         self?.$store?.state?.resSignUpData?.access_token,
        //       "X-Language-Code":
        //         localStorage?.getItem("language") || self?.$store?.state?.language,
        //     },
        //   }
        // );
        if (resUser.status == 200) {
          // console.log(resUser);
          // resUser?.data?.result?.map((el) => {
          //   tmpEmailUser.push(el?.email);
          // });
          tmpEmailUser = resUser?.data?.result || [];
        }
        // if (resStaff.status == 200) {
        //   // console.log(resStaff);
        //   resStaff?.data?.result?.map((el) => {
        //     if (el?.email) tmpEmailStaff.push(el?.email);
        //   });
        // }
        // if (resPropertyUser.status == 200) {
        //   // console.log(resPropertyUser);
        //   resPropertyUser?.data?.result?.map((el) => {
        //     if (el?.email) tmpEmailPropertyUser.push(el?.email);
        //   });
        // }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.loadingInvite = false;
        self.checking_email = false;
        // tmpEmailUser = tmpEmailUser.concat(tmpEmailStaff);
        // tmpEmailUser = tmpEmailUser.concat(tmpEmailPropertyUser);
        tmpEmailUser = tmpEmailUser.filter(
          (item, pos) => tmpEmailUser.indexOf(item) === pos
        );
        self.itemsUserEmail = tmpEmailUser;
        // console.log("emails: ", self.itemsUserEmail);
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    async checkDupEmailFunc() {
      const self = this;
      self.checking_email = true;
      self.dup_email = null;
      self.can_invite = null;
      EventBus.$emit("loadingtillend");
      var elementEmail = document.getElementById("inviteemail");

      if (self.invite_user && self.validateEmail(self.invite_user)) {
        if (self.value_element) {
          document
            .getElementById(self.value_element)
            .classList.remove("border-pink-focus");
          self.value_element = "";
        }
        self.initAllUser().then(() => {
          const dupEmail = self?.itemsUserEmail?.find(
            (p) => p.email == self.invite_user
          );
          var dupProperty = false;
          dupEmail?.property.map((el) => {
            el?.properties?.find((p) => {
              if (p == self.propertyID) dupProperty = true;
            });
          });

          // console.log("dupEmail: ", dupEmail, dupProperty);
          if (dupEmail?._id && dupEmail?.role !== "staff") {
            self.dup_email = true;
            self.dup_property = dupProperty;
            self.can_invite = false;
          } else if (dupEmail?._id && dupProperty) {
            self.dup_email = true;
            self.dup_property = true;
            self.can_invite = false;
          } else {
            // console.log("111");
            self.dup_email = false;
            self.dup_property = false;
            self.can_invite = true;
          }

          self.checking_email = false;
          EventBus.$emit("endloading");
        });
      } else if (self.invite_user && !self.validateEmail(self.invite_user)) {
        elementEmail.classList.add("border-pink-focus");
        self.value_element = "inviteemail";
        self.checking_email = false;
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Signup.wrongemail"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 2000,
          }
        );
      } else {
        elementEmail.classList.add("border-pink-focus");
        self.value_element = "inviteemail";
        self.checking_email = false;
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SigninUserAndRole.pls_fill_email"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 2000,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

.btn {
  min-height: 42px !important;
}

.btn .invite {
  font-size: 1rem;
  min-height: 42px !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.border-success-focus {
  border-color: #4caf50 !important;
}

.text-white-btn {
  color: #fff !important;
}
</style>
